<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<template>
    <div class="page-content w-100">
        <div class="row g-0">
  <div class="col-sm-12">
    <p class="text-center page-heading">Reports</p>
  </div>
</div>
<div class="px-4" v-if="clickLink">
    <h3 class="my-2 showing-link" @click="showUsers()">Users added to RxIx</h3>
    <h3 class="my-2 showing-link" @click="showUserCreatedByDoctor()">Patient consultation by doctor</h3>
</div>

<div class="px-4" v-if="viewUser">
<div class="row">
    <div class="col-md-12 mx-auto">
        <div class="px-2">
            <div class="row reportHeading">
                <div class=" col-10  mb-0 ">
        <h3 class="postPatientGroup" >Users added to RxIx within a period</h3>
      </div>
      <div class="col-2 mb-0 mt-0">
        <p class="closeButton text-align text-end " @click="GoSetting()"><font-awesome-icon :icon="['fas','times']" /></p>
      </div>
            </div>
            <div class="row mt-3">
                <div class="col-12 col-sm-2">
                    <label for="" >From</label>
                    <input type="date" id="datepicker" class="form-control"  :min="minimumDate" :max="maxDate" v-model="startDate" />
                </div>
                <div class="col-12 col-sm-2">
                    <label for="">To</label>
                    <input type="date" id="datepicker" class="form-control" :min="startDate" :max="maxDate" v-model="toDate" />
                </div>
                <div class="col-12 col-sm-1 mt-4">
                    <button class="btn btn-blue-color text-white adminBtn" @click="reportDateRange(startDate, toDate)" :disabled="!recordDate">Go</button>
                </div>
                <div class=" col-12 col-sm-1 mt-4">
                    <button class="btn btn-color text-white adminBtn" @click="clearBtn()" >Clear</button>
                </div>
                <div class="col-12 col-sm-4 mt-3">
                    <button class="SignupWhiteBtn btn" @click="downloadRolesCSV" :disabled="!fullUploadRecordDate.length || !showDate">Download report</button>
                </div>
                
            </div>
            <div class="mt-4">
                <div class="hr-border"></div>
            </div>
        </div>
    </div>
</div>
<table class="table table-light table-striped text-align text-center my-3" v-if="fullUploadRecordDate.length && showDate" >
    <tbody>
    <tr class="rolesTableBg">
        <td class="rolesTableBg"><b>Date</b></td>
        <td class="rolesTableBg"><b>#Doctors</b></td>
        <td class="rolesTableBg"><b>Cum. doctors</b></td>
        <td class="rolesTableBg"><b>#Users</b></td>
        <td class="rolesTableBg"><b>Cum. users</b></td>
        <td class="rolesTableBg"><b>#Fly members</b></td>
        <td class="rolesTableBg"><b>Cum. fly members</b></td>
    </tr>
    <tr class="table-secondary" v-for="roles in fullUploadRecordDate" :key="roles">
        <td class="table-secondary">{{ roles.date }}</td>
        <td class="table-secondary" >{{ roles.hcpcount ? roles.hcpcount : '-' }}</td>
        <td class="table-secondary">{{ roles.cumulativeHcpCount ? roles.cumulativeHcpCount : '-' }}</td>
        <td class="table-secondary">{{ roles.usercount ? roles.usercount : '-' }}</td>
        <td class="table-secondary">{{ roles.cumulativeUserCount ? roles.cumulativeUserCount : '-' }}</td>
        <td class="table-secondary">{{ roles.familymembercount ? roles.familymembercount : '-' }}</td>
        <td class="table-secondary">{{ roles.cumulativeFamilyMemberCount ? roles.cumulativeFamilyMemberCount : '-' }}</td>
    </tr>
</tbody>
</table>
<div v-if="!fullUploadRecordDate.length && showDate" class="my-2">
    <h3  class="text-center noRecordText">No records found</h3>
</div>
</div>
<div class="px-4" v-if="viewUserCreatedByDoctor">
<div class="row">
    <div class="col-md-12 mx-auto">
        <div class="px-2">
            <div class="row reportHeading">
                <div class=" col-10  mb-0 ">
        <h3 class="postPatientGroup" >Patient consultation by a doctor within a period</h3>
      </div>
      <div class="col-2 mb-0 mt-0">
        <p class="closeButton text-align text-end " @click="GoSetting()"><font-awesome-icon :icon="['fas','times']" /></p>
      </div>
       </div>
        <div>
        <select class="form-control admindropdown" name="select" id="select" v-model="selected" @change="getDocID($event)">
        <option value="1">Select doctor</option>
        <option v-for="getName in doctorNameArray" :key="getName">{{ getName.doctor_name }}</option>
        </select>
        </div>
        <div class="row mt-3">
        <div class="col-12 col-sm-2">
            <label for="" >From</label>
            <input type="date" id="datepicker" class="form-control" :disabled="selected === '1'" :min="minmumConsultDate" :max="maxDate" v-model="startDate" />
        </div>
        <div class="col-12 col-sm-2">
            <label for="">To</label>
            <input type="date" id="datepicker" class="form-control" :disabled="selected === '1'" :min="startDate" :max="maxDate" v-model="toDate" />
        </div>
        <div class="col-12 col-sm-1 mt-4">
            <button class="btn btn-blue-color text-white adminBtn" @click="getDoctorConsultationsResult(startDate, toDate)" :disabled="!recordDate">Go</button>
        </div>
        <div class=" col-12 col-sm-1 mt-4">
            <button class="btn btn-color text-white adminBtn" @click="clearBtn()" >Clear</button>
        </div>
        <div class="col-12 col-sm-4 mt-3">
            <button class="SignupWhiteBtn btn" @click="downloadDocConultationCSV" :disabled="!doctorConsultationCount.length || !showDate">Download report</button>
        </div>
        </div>
        <div class="mt-4">
            <div class="hr-border"></div>
        </div>
        </div>
    </div>
</div>
<table class="table table-light table-striped text-align text-center my-3" v-if="doctorConsultationCount.length && showDate" >
    <tbody>
    <tr class="rolesTableBg">
        <td class="rolesTableBg"><b>Date</b></td>
        <td class="rolesTableBg"><b>New users</b></td>
        <td class="rolesTableBg"><b>Existing users</b></td>
        <td class="rolesTableBg"><b>Family members</b></td>
        <td class="rolesTableBg"><b>Total</b></td>
    </tr>
    <tr class="table-secondary" v-for="roles in doctorConsultationCount" :key="roles">
        <td class="table-secondary">{{ roles.date }}</td>
        <td class="table-secondary" >{{ roles.newusercount ? roles.newusercount : '-' }}</td>
        <td class="table-secondary">{{ roles.existingusercount ? roles.existingusercount : '-' }}</td>
        <td class="table-secondary">{{ roles.familymembercount ? roles.familymembercount : '-' }}</td>
        <td class="table-secondary">{{ roles.total ? roles.total : '-' }}</td>
    </tr>
</tbody>
</table>
<div v-if="!doctorConsultationCount.length && showDate" class="my-2">
    <h3  class="text-center noRecordText">No records found</h3>
</div>
</div>
</div>
</template>
<script>
import axios from 'axios';
import moment from 'moment';
export default {
components: {

},
data() {
    return {
        startDate: '',
        toDate: '',
        showDate: false,
        fullUploadRecordDate:[],
        viewUser:false,
        clickLink:true,
        minimumDate:'',
        viewUserCreatedByDoctor:false,
        tor:false,
        doctorNameArray:[],
        selected: '1',
        doctorConsultationCount:[],
        doctorUniId:'',
        minmumConsultDate:''
    }
},
computed:{
    recordDate(){
        return this.toDate && this.startDate
    },
    maxDate() {
  return new Date().toISOString().split('T')[0];
},

},
created: async function () {
   await this.oldestDate();
   await this.consultationDoctors();
},
methods: {
    GoSetting(){
    this.clickLink=true;
    this.viewUser=false
    this.viewUserCreatedByDoctor=false
    this.selected= '1';
    this.startDate="",
    this.toDate=""
    this.showDate=false,
    this.fullUploadRecordDate=[],
    this.doctorConsultationCount=[]
    },
    showUserCreatedByDoctor(){
        this.viewUserCreatedByDoctor=true
        this.clickLink=false;
    },
    showUsers(){
        this.viewUser=true;
        this.clickLink=false;
    },
    clearBtn(){
        this.selected= '1';
        this.startDate="",
        this.toDate=""
        this.showDate=false,
        this.fullUploadRecordDate=[],
        this.doctorConsultationCount=[]
    },
    oldestDate(){
        const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
        axios
        .get(`${BASE_API_URL}/earlierDate`)
            .then(async (response) => {
                this.minimumDate =response.data.minimumDate
            })
    },
    async reportDateRange(startDate, toDate) {
        this.showDate = true
        this.fullUploadRecordDate=[]
        const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
        await axios
            .get(`${BASE_API_URL}/user-role/date?start_date=${startDate}&end_date=${toDate}`)
            .then(async (response) => {
                this.fullUploadRecordDate=response.data
     })
    },
    downloadRolesCSV() {
      const csvContent = this.convertRolesToCSV();
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'report.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    convertRolesToCSV() {
      const header = ['Date', '#Doctors', 'Cum. doctors', '#Users', 'Cum. Users', '#Fly members', 'Cum. Fly members'].join(',');
      const rows = this.fullUploadRecordDate.map(row =>
        [row.date, row.hcpcount, row.cumulativeHcpCount, row.usercount, row.cumulativeUserCount , row.familymembercount, row.cumulativeFamilyMemberCount].join(',')
      );
      return [header, ...rows].join('\n');
    },
    consultationDoctors(){
        const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
        axios
        .get(`${BASE_API_URL}/doctornames`)
            .then(async (response) => {
                this.doctorNameArray =response.data
                this.doctorNameArray.map((item)=>{
                    item.created_at = moment(item.created_at).format('YYYY-MM-DD');
                })
            })
    },
    getDocID(event){
        this.doctorNameArray.map((docname)=>{
            if(docname.doctor_name == event.target.value){
                this.doctorUniId= docname.doctor_id,
                this.minmumConsultDate= docname.created_at
            }
        })
    },
   async getDoctorConsultationsResult(startDate, toDate){
    this.showDate = true
    this.doctorConsultationCount=[]
    const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
    await axios
    .get(`${BASE_API_URL}/doc-consultation-count?start_date=${startDate}&end_date=${toDate}&doctor_id= ${this.doctorUniId}`)
        .then(async (response) => {
            this.doctorConsultationCount=response.data
     })
    },
    downloadDocConultationCSV() {
      const csvContent = this.convertDocConultationToCSV();
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'doc-consultatio .csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    convertDocConultationToCSV() {
      const header = ['Date', 'New users', 'Existing users', 'Family members', 'Total'].join(',');
      const rows = this.doctorConsultationCount.map(row =>
        [row.date, row.newusercount, row.existingusercount, row.familymembercount, row.total].join(',')
      );
      return [header, ...rows].join('\n');
    },
}
}
</script>
<style>
.adminBtn{
    width: 65px;
}
select.form-control.admindropdown {
    width: 200px;
    font-size: 20px;
    /* margin-left: auto;
    margin-right: auto; */
    text-align: left;
    margin-bottom: 5px;
    -webkit-appearance : auto;
    cursor: pointer;
    }
.SignupWhiteBtn{
/* border: 2px solid #00979e !important; */
background-color: #43a6b6 !important;
font-size: 20px;
color: #fff !important;
}
.rolesTableBg{
    --bs-table-bg-type: #00979e !important;
    color: #fff !important;
}
.showing-link{
    color: #00979e;
    cursor: pointer;
}
.plusMinusIcon{
cursor: pointer;
width: 20px;
}
.reportHeading{
margin-top: 1rem;
}
.noRecordText {
color: red;
}
</style>